/*eslint-disable camelcase*/
import { useEffect, useMemo } from 'react';
import { getConfig } from '../utils/config';
import { resolveAuthorization } from '../utils/auth';
import { parseCookies } from '../utils/parseCookies';
import {
  filterZonesByPrefix,
  getFactOfTheDay,
  getSponsorshipStyles,
  normalizeSponsorship,
  normalizeZones,
} from '../utils/data';
import { isBfExternalLink } from '../utils/isBfExternalLink';
import HomepageSiteHeader from '../components/SiteHeader';
import Splash from '../components/Splash';
import PromoBar from '../components/PromoBar'; // move this component to different dir
import HomeFeedTabs from '../components/HomePage/HomeFeedTabs';
import MembershipPromoBar from '../components/MembershipPromoBar';

// apis
import { getSiteHeader } from '../upstreams/bf-header-ui';
import { getPageByName } from '../upstreams/feed-api';
import setResponseHeaders from '@buzzfeed/headers';
import { MAX_AGE_SHORT } from '../constants/feeds';
import { CLUSTER } from '../constants';
import { getFeedPageSurrogateKeys } from '../utils/surrogate-keys';

// other components
import CustomHead from './_head';
import HomepageWrapper from '../components/HomepageWrapper';
import SponsoredBar from '../components/SponsoredBar';
import AdShieldScript from '../components/AdShieldScript';

// ad components
import AdPlaceholder from '../components/Ads/units/AdPlaceholder';
import AdSectionAwareness from '../components/Ads/units/AdSectionAwareness';

export function Page({
  adsDisabled,
  header,
  pageConfig,
  previewTimestamp,
  sponsorship,
  userGeo,
  zones = {},
 }) {
  const splashZones = useMemo(() => filterZonesByPrefix(zones, 'splash_'), [zones]);
  const tabZones = useMemo(() => filterZonesByPrefix(zones, 'tab_'), [zones]);
  const abTestZones = useMemo(() => filterZonesByPrefix(zones, 'ab_test'), [zones]);

  const hasSponsoredTab = !!tabZones?.tab_sponsored?.items?.length;

  const inlineCss = useMemo(() => getSponsorshipStyles(sponsorship), [sponsorship]);

  const factOfTheDay = getFactOfTheDay(zones?.fact_of_the_day?.items, previewTimestamp);

  const promoBar = zones.promo?.items?.[0] || null;
  const promoBarContent = promoBar?.content || null;

  const postFactOfTheDay = (eventMessage) => {
    if (eventMessage?.data?.type === 'topic-nav-loaded' && factOfTheDay?.text) {
      window.postMessage(
        {
          type: 'fact-of-the-day',
          message: { ...factOfTheDay, forceNewBrowserTab: isBfExternalLink(factOfTheDay?.url)}
        },
        '*'
      );
    }
  };

  useEffect(() => {
    window.addEventListener('message', postFactOfTheDay);

    return () => {
      window.removeEventListener('message', postFactOfTheDay);
    };
  }, []);

  return (
    <>
      <HomepageWrapper pageName={'home'} userGeo={userGeo}>
        {!adsDisabled && <AdShieldScript />}
        <HomepageSiteHeader {...header} />
        <CustomHead pageConfig={pageConfig} inlineCss={inlineCss} userGeo={userGeo} />
        <MembershipPromoBar />
        <SponsoredBar data={sponsorship?.data?.tab} sponsor={sponsorship?.sponsor} hasSponsoredTab={hasSponsoredTab} />
        <Splash zones={splashZones} isTrackable={true} hasSponsoredTabEnabled={hasSponsoredTab && sponsorship?.data?.tab?.enabled} />
        {promoBarContent?.type === 'manual' && (
          <PromoBar
            item={promoBar}
            className="feed-content-area"
            trackingData={{
              unit_type: 'feed',
              unit_name: 'splash',
              position_in_unit: 4,
            }}
          />
        )}
        <AdPlaceholder className={`Ad--section-awareness-wrapper ${sponsorship ? 'feed-content-area' : ''}`}>
          <AdSectionAwareness
            startAdIndexMobile={2}
            position="body"
            hasSponsoredTabEnabled={hasSponsoredTab && sponsorship?.data?.tab?.enabled}
          />
        </AdPlaceholder>
        <main className="feed-content-area home-page">
          <HomeFeedTabs
            tabZones={tabZones}
            abTestZones={abTestZones}
            startAdIndexMobile={3}
            sponsorship={sponsorship}
          />
        </main>
      </HomepageWrapper>
    </>
  );
}

export async function getServerSideProps({ req, res }) {
  const { preview_timestamp, s } = req.query;
  const edition = 'en-us'; // for future use when determining editions after mvp rollout
  const staticProps = {
    namespacesRequired: ['common'],
  };

  // whether to bypass caching in redis.  Skip caching in dev on stage
  //  to match bypassing Fastly as well.  Also skip when using preview
  //  timestamp for viewing future sponsorships
  const skipCache = CLUSTER !== 'prod' || !!preview_timestamp;

  const userGeo = parseCookies(req)['bf-geo-country'] || '';

  // disable ads for buzzfeed plus members
  const adsDisabled = req.get('X-BFMem-Type') === 'buzzfeed_plus';

  try {
    const [
      header, // Fetch Error --> 500
      feedData, // Fetch Error --> 500
      // eslint-disable-next-line no-undef
    ] = await Promise.all([
      getSiteHeader({ edition, isHomepage: true }),
      getPageByName({
        edition,
        preview_timestamp,
        skipCache,
        tags: ['type:page', 'upstream:feed_api', 'page_type:home']
      }),
    ]);

    if (!feedData || (feedData instanceof Object && !Object.keys(feedData).length)) {
      // if the fetch from feed API is a success but there's no data in the feed,
      //  throw an error, as it should always exist for the homepage
      throw new Error('No feed data found from feed API');
    }

    // Only require auth if user is coming via query param
    const authorization = (s && s === 'new_hp') ? 'bmV3OmhvbWVwYWdl' : null;
    resolveAuthorization({ req, res }, authorization);

    /**
     * @todo
     * Hopefully very short term solutions for the following:
     * - WEB-4274: Weaving the same additional trending items into multiple zones, trending splash
     *   and trending tab
     */
    const updatedZones = feedData.zones;
    const annexTrending = updatedZones.find((zone) => zone.name === 'trending_annex');
    updatedZones.forEach((zone, index) => {
      if (zone.name === 'splash_trending') {
        if (annexTrending) {
          // append the additional trending items to the end
          updatedZones[index].items.push(...annexTrending.items);
        }
      }
    });
    const zones = normalizeZones(updatedZones, {
      funModuleOverride: req.query.funModule || null,
      originTag: '',
    });
    const sponsorship = normalizeSponsorship(feedData?.sponsorships);

    const customKeys = getFeedPageSurrogateKeys({ feedPage: feedData, cluster: CLUSTER });

    setResponseHeaders({
      req,
      res,
      customKeys,
      /**
       * A max-age is defined only if served on prod and there is no basic auth required. Otherwise
       * max-age will be `0`.
       * @todo make longer once full event driven purging is set up
       */
      maxAge: CLUSTER === 'prod' && !feedData.authorization ? MAX_AGE_SHORT : 0,
      edition: 'en-us',
    });

    return {
      props: {
        ...staticProps,
        debug: { funModule: req.query.funModule || null }, // @todo remove
        adsDisabled,
        header,
        userGeo,
        metadata: feedData.metadata,
        pageConfig: getConfig({ page: 'default', edition, path: req.path }),
        sponsorship,
        tracking: {
          unit_type: 'feed',
          unit_name: 'main',
          context_page_type: 'feed',
          context_page_id: 1,
          destination: 'buzzfeed',
          page_edition: 'en-us',
        },
        zones,
        previewTimestamp: preview_timestamp ? preview_timestamp * 1000 : null,
      },
    };
  } catch (error) {
    // Handle error (WARNING: this text is used for alert monitor, so beware changing wording)
    console.error('getServerSideProps failed to complete execution on home page');
    console.error(error);
    throw error;
  }
}

export default Page;
